export const SuperPsychologist = ({ color, width, height }) => {
  return (
    <svg width={width} height={height} fill='none' viewBox={`0 0 ${width} ${height}`}>
      <path
        stroke={color}
        strokeMiterlimit='10'
        d='M10.91 20.817a9.418 9.418 0 100-18.836 9.418 9.418 0 000 18.836z'
      />
      <path
        stroke={color}
        strokeLinecap='round'
        strokeLinejoin='round'
        d='M10.91 17.77a6.37 6.37 0 01-6.372-6.37M4.261 18.324v10.525l6.648-3.877 6.648 3.877V18.324'
      />
    </svg>
  )
}
SuperPsychologist.defaultProps = {
  color: '#FFB90B',
  width: '22',
  height: '30'
}
