import React from 'react'

export const Speak = ({ size, color }) => (
  <svg width={size} height={size} viewBox='0 0 16 16'>
    <g transform='translate(0)'>
      <path
        fill={color}
        d='M15.14,9.58a.561.561,0,0,0-.814-.345c-.323.221-.474.494-.345.814l.736,1.821-2.186-.964a.625.625,0,0,0-.5,0,6.028,6.028,0,0,1-2.379.48A5.415,5.415,0,0,1,4.061,6.317,5.356,5.356,0,0,1,9.655,1.25a5.356,5.356,0,0,1,5.594,5.067c0,.218-.223,2.287-1.108,3.067-.051.341,1.058,1.006,1.108.665A6.5,6.5,0,0,0,16.5,6.317a6.041,6.041,0,0,0-2.025-4.486A7.1,7.1,0,0,0,9.655,0,7.1,7.1,0,0,0,4.836,1.831a6.075,6.075,0,0,0-2,3.948A5.053,5.053,0,0,0,.5,9.985a4.849,4.849,0,0,0,.958,2.9L.544,15.141a.625.625,0,0,0,.832.806l2.632-1.16a5.948,5.948,0,0,0,2.084.371l.046,0a5.916,5.916,0,0,0,2.941-.8,5.38,5.38,0,0,0,1.891-1.841,7.2,7.2,0,0,0,1.3-.361l3.353,1.478a.625.625,0,0,0,.831-.806ZM6.093,13.906H6.067a4.687,4.687,0,0,1-1.826-.373.625.625,0,0,0-.5,0l-1.464.645.472-1.168a.625.625,0,0,0-.1-.64,3.648,3.648,0,0,1-.9-2.389A3.718,3.718,0,0,1,2.9,7.322,6.309,6.309,0,0,0,4.753,10.7,6.986,6.986,0,0,0,9.3,12.625,4.594,4.594,0,0,1,6.093,13.906Zm4.469-7a.625.625,0,1,1,0,1.25H6.811a.625.625,0,1,1,0-1.25Zm1.875-1.25H6.811a.625.625,0,1,1,0-1.25h5.625a.625.625,0,1,1,0,1.25Zm0,0'
        transform='translate(-0.499)'
      />
    </g>
  </svg>
)

Speak.defaultProps = {
  color: '#212121',
  size: 16
}
