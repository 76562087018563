import React from 'react'

export const LobbyError = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24'>
      <g transform='translate(-447.005 -4383)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M459.005,4406.25a11.25,11.25,0,1,0-11.25-11.25A11.25,11.25,0,0,0,459.005,4406.25Z'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M454.505,4399.5l9-9'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M463.505,4399.5l-9-9'
        />
      </g>
    </svg>
  )
}

LobbyError.defaultProps = {
  color: '#db0000',
  size: 22
}
