import React, { useMemo } from 'react'

export const BoticarioSadFace = ({ outlined, height }) => {
  const color = useMemo(() => (outlined ? '#fff' : '#db0000'), [outlined])
  const size = useMemo(() => (height ? height : 40), [height])
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 39.938 40'>
      <path
        d='M32.28 16.93a1.562 1.562 0 00-.766-2.073c-.869-.4-3.822-1.706-5.341-1.706a2.706 2.706 0 00-3.125 3.125 1.563 1.563 0 003.125 0 17.256 17.256 0 014.034 1.419 1.561 1.561 0 002.073-.766zM9.873 17.696a17.247 17.247 0 014.034-1.419 1.563 1.563 0 003.125 0 2.706 2.706 0 00-3.125-3.126c-1.519 0-4.472 1.306-5.341 1.706a1.563 1.563 0 101.307 2.839z'
        fill={color}
        transform='translate(0 -.027)'
      ></path>
      <path
        d='M39.938 20.026A19.7 19.7 0 0033.98 5.883 20.375 20.375 0 0019.829.028h-.056A20 20 0 005.858 34.169a19.852 19.852 0 0011.069 5.624h-.441a20.757 20.757 0 003.112.234h.4a20.076 20.076 0 003.06-.234h-.337a20.313 20.313 0 0011.259-5.624 19.7 19.7 0 005.958-14.143zM19.8 36.9a16.874 16.874 0 010-33.746 17.036 17.036 0 0116.96 16.872A17.036 17.036 0 0119.8 36.9z'
        fill={color}
        transform='translate(0 -.027)'
      ></path>
      <path
        d='M27.75 27.876a9 9 0 00-15.843 0 1.563 1.563 0 102.749 1.487 5.877 5.877 0 0110.344 0 1.563 1.563 0 002.749-1.487z'
        fill={color}
        transform='translate(0 -.027)'
      ></path>
    </svg>
  )
}
