import React, { useMemo } from 'react'

export const BoticarioRegularFace = ({ outlined, height }) => {
  const color = useMemo(() => (outlined ? '#fff' : '#f7b206'), [outlined])
  const size = useMemo(() => (height ? height : 40), [height])

  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 40.01 40'>
      <g transform='translate(-285.946 -375.66)'>
        <path
          d='M305.953 375.66c-.213 0-.443 0-.707.011a20 20 0 00.007 39.976c.231.013.47.013.7.013a20 20 0 100-40zm.007 36.876c-.248 0-.483-.007-.707-.015a16.876 16.876 0 01-.007-33.721c.235-.015.473-.015.707-.015a16.875 16.875 0 01.007 33.75z'
          fill={color}
        ></path>
        <path
          d='M0 0L12.199 0'
          fill='#399d4c'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3px'
          transform='translate(300.046 401.616)'
        ></path>
        <path
          d='M0 0L5.198 0'
          fill='#399d4c'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3px'
          transform='translate(297.245 390.757)'
        ></path>
        <path
          d='M0 0L5.198 0'
          fill='#399d4c'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='3px'
          transform='translate(309.848 390.757)'
        ></path>
      </g>
    </svg>
  )
}
