import React from 'react'

export const WiFi = ({ color, size }) => (
  <svg width={size} viewBox='0 0 19.623 16.714'>
    <g transform='translate(0.919 0.65)'>
      <g transform='translate(0)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M371.371,427.493A2.371,2.371,0,1,0,369,425.121,2.371,2.371,0,0,0,371.371,427.493Z'
          transform='translate(-362.479 -412.079)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M375.078,419.987a5.929,5.929,0,0,0-8.384,0'
          transform='translate(-361.995 -411.137)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M376.929,416.529a9.485,9.485,0,0,0-13.414,0'
          transform='translate(-361.329 -410.194)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.3px'
          d='M378.535,412.753a13.042,13.042,0,0,0-17.785,0'
          transform='translate(-360.75 -409.251)'
        />
      </g>
    </g>
  </svg>
)

WiFi.defaultProps = {
  color: '#FF7708',
  size: 18
}
