import React from 'react'

export const IconDelete = ({ color, size }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M14.9963 29.0626C17.7776 29.0626 20.4965 28.2378 22.8091 26.6926C25.1216 25.1474 26.924 22.9511 27.9884 20.3815C29.0527 17.812 29.3312 14.9844 28.7886 12.2565C28.246 9.52868 26.9067 7.0232 24.94 5.05653C22.9734 3.08985 20.4676 1.75026 17.7398 1.20765C15.0119 0.665047 12.1845 0.94377 9.61487 2.00813C7.04528 3.07248 4.84904 4.8747 3.30383 7.18727C1.75863 9.49983 0.933838 12.2188 0.933838 15.0001C0.933838 18.7297 2.41541 22.3064 5.05264 24.9436C7.68987 27.5808 11.2667 29.0626 14.9963 29.0626Z'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M9.37134 20.625L20.6213 9.375'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M20.6213 20.625L9.37134 9.375'
      stroke={color}
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

IconDelete.defaultProps = {
  size: 30,
  color: '#DB0000'
}
