import React, { useMemo } from 'react'

export const BoticarioHappyFace = ({ outlined, height }) => {
  const color = useMemo(() => (outlined ? '#FFF' : '#27ae60'), [outlined])
  const size = useMemo(() => (height ? height : 40), [height])
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width={size} height={size} viewBox='0 0 40 40'>
      <path
        d='M19.69 40.794v-3.126c.224.008.459.015.707.015a16.875 16.875 0 00-.007-33.75c-.234 0-.472 0-.707.015V.818c.264-.011.494-.011.707-.011A20 20 0 0134.534 34.95a19.869 19.869 0 01-14.144 5.857c-.23 0-.469 0-.7-.013zm0 0a20 20 0 010-39.976v3.129a16.876 16.876 0 000 33.721v3.126zm0-9.074v-3.135c.251.023.513.034.781.034a8.765 8.765 0 005.908-2.266 10.19 10.19 0 002.011-2.383 1.561 1.561 0 011.374-.82 1.582 1.582 0 01.744.188 1.567 1.567 0 01.63 2.119c-.14.257-3.5 6.287-10.674 6.287-.256.001-.518-.008-.774-.024zm0 .008c-6.735-.335-9.909-6.029-10.039-6.271a1.565 1.565 0 01.627-2.119 1.582 1.582 0 01.743-.188 1.566 1.566 0 011.376.82 10.178 10.178 0 002.018 2.383 8.782 8.782 0 005.275 2.245v3.13zm8.366-14.672a.782.782 0 00-1.564 0 1.563 1.563 0 01-3.126 0 3.907 3.907 0 017.813 0 1.562 1.562 0 11-3.124 0zm-13.75 0a.783.783 0 00-1.565 0 1.563 1.563 0 11-3.125 0 3.906 3.906 0 117.812 0 1.561 1.561 0 11-3.121 0z'
        fill={color}
        transform='translate(-.39 -.807)'
      ></path>
    </svg>
  )
}
