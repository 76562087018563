import React from 'react'

export const ReferralGift = ({ color, ...rest }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='19.109'
    height='19.108'
    viewBox='0 0 17.109 17.108'
    {...rest}
  >
    <path
      fill={color || '#FF7708'}
      stroke={color || '#212121'}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='0px'
      d='M15.572 2.952h-3.879A1.871 1.871 0 008.835.56a1.494 1.494 0 00-.281.4 1.5 1.5 0 00-.281-.4 1.871 1.871 0 00-2.858 2.392H1.537A1.539 1.539 0 000 4.49v1.2a1.54 1.54 0 001.069 1.467v8.427a1.539 1.539 0 001.537 1.537H14.5a1.539 1.539 0 001.537-1.537V7.157a1.54 1.54 0 001.069-1.464V4.49a1.539 1.539 0 00-1.534-1.538zM9.089 6.16H8.02V4.022h1.069zm.126-3.66c.165-.95.355-1.163.376-1.184a.802.802 0 011.134 1.134c-.021.021-.233.21-1.175.375-.137.024-.271.044-.395.061.017-.121.036-.252.06-.386zM6.383 1.316a.8.8 0 011.134 0c.021.021.21.233.375 1.175.024.137.044.271.061.395a10.25 10.25 0 01-.386-.059c-.95-.165-1.163-.355-1.184-.376a.8.8 0 010-1.134zM1.069 4.49a.468.468 0 01.468-.468H6.95V6.16H1.537a.468.468 0 01-.468-.468zm1.07 11.093V7.23H6.95v8.822H2.606a.468.468 0 01-.467-.469zm5.881.468V7.23h1.069v8.822zm6.95-.468a.468.468 0 01-.468.468h-4.344V7.23h4.812zm1.069-9.891a.468.468 0 01-.468.468h-5.413V4.022h5.413a.468.468 0 01.468.468z'
      transform='translate(0 -.013)'
    ></path>
  </svg>
)
