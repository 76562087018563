import React from 'react'

export const WirelessSignal = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 22.311'>
      <g transform='translate(0.75 1.057)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M363.005,6415.57a2.25,2.25,0,1,0-2.25-2.25A2.251,2.251,0,0,0,363.005,6415.57Z'
          transform='translate(-351.755 -6404.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M363.005,6415.57v9.684'
          transform='translate(-351.755 -6404.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M370.291,6421.893a11.249,11.249,0,0,0,0-17.143'
          transform='translate(-351.755 -6404.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M367.376,6418.466a6.748,6.748,0,0,0,0-10.286'
          transform='translate(-351.755 -6404.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M355.719,6421.893a11.249,11.249,0,0,1,0-17.143'
          transform='translate(-351.755 -6404.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M358.634,6418.466a6.748,6.748,0,0,1,0-10.286'
          transform='translate(-351.755 -6404.75)'
        />
      </g>
    </svg>
  )
}

WirelessSignal.defaultProps = {
  color: '#FF7708',
  size: 22
}
