import React from 'react'

export const Headphones = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 24 24'>
      <g transform='translate(0.75 0.75)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M403.505,2125h-.75a3,3,0,0,1-3-3v-3a3,3,0,0,1,3-3h.75a.75.75,0,0,1,.75.75v7.5A.75.75,0,0,1,403.505,2125Z'
          transform='translate(-399.755 -2107.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M419.255,2125h-.75a.75.75,0,0,1-.75-.75v-7.5a.75.75,0,0,1,.75-.75h.75a3,3,0,0,1,3,3v3A3,3,0,0,1,419.255,2125Z'
          transform='translate(-399.755 -2107.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M402.755,2116a8.25,8.25,0,0,1,8.25-8.25h0a8.25,8.25,0,0,1,8.25,8.25'
          transform='translate(-399.755 -2107.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M414.005,2128.75h2.25a3,3,0,0,0,3-3h0V2125'
          transform='translate(-399.755 -2107.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M412.505,2130.25h-1.5a1.5,1.5,0,0,1-1.5-1.5h0a1.5,1.5,0,0,1,1.5-1.5h1.5a1.5,1.5,0,0,1,1.5,1.5h0A1.5,1.5,0,0,1,412.505,2130.25Z'
          transform='translate(-399.755 -2107.75)'
        />
      </g>
    </svg>
  )
}

Headphones.defaultProps = {
  color: '#FF7708',
  size: 22
}
