import React from 'react'

export const SadFaceEmoji = ({ size, primaryColor, secondaryColor }) => (
  <svg width={size} height={size} viewBox='0 0 52.994 52.994'>
    <path
      fill={primaryColor}
      d='M18.736,0A18.736,18.736,0,1,1,0,18.736,18.736,18.736,0,0,1,18.736,0Z'
      transform='translate(0 26.497) rotate(-45)'
    />
    <g transform='translate(18.769 26.311)'>
      <path
        fill={secondaryColor}
        d='M-90.926-247.245a2.428,2.428,0,0,1-2.239,2.581,2.428,2.428,0,0,1-2.239-2.581,2.428,2.428,0,0,1,2.239-2.581A2.429,2.429,0,0,1-90.926-247.245Z'
        transform='translate(95.404 249.826)'
      />
      <g transform='translate(10.806)'>
        <path
          fill={secondaryColor}
          d='M-22.324-247.245a2.428,2.428,0,0,1-2.239,2.581,2.428,2.428,0,0,1-2.239-2.581,2.428,2.428,0,0,1,2.239-2.581A2.428,2.428,0,0,1-22.324-247.245Z'
          transform='translate(26.802 249.826)'
        />
      </g>
      <g transform='translate(0.422 8.952)'>
        <path
          fill={secondaryColor}
          d='M-79.881-189.117a.929.929,0,0,1-.564-.19,10.265,10.265,0,0,0-11.749,0,.932.932,0,0,1-1.307-.178.933.933,0,0,1,.178-1.307,12.239,12.239,0,0,1,14.008,0,.933.933,0,0,1,.178,1.307A.931.931,0,0,1-79.881-189.117Z'
          transform='translate(93.692 192.995)'
        />
      </g>
    </g>
  </svg>
)

SadFaceEmoji.defaultProps = {
  primaryColor: '#f8f8f8',
  secondaryColor: '#dcdedf',
  size: 53
}
