import React from 'react'

export const Webcam = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 21 23.996'>
      <g transform='translate(0.75 0.75)'>
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M263.308,2296.93c-1.058,1.566.442,4.566,1.942,5.316h-16.5c1.5-.75,3-3.75,1.942-5.316'
          transform='translate(-247.25 -2279.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M257,2294a4.5,4.5,0,1,0-4.5-4.5A4.5,4.5,0,0,0,257,2294Z'
          transform='translate(-247.25 -2279.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M257,2289.12a.375.375,0,1,0,.375.375.375.375,0,0,0-.375-.375h0'
          transform='translate(-247.25 -2279.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M252.58,2280.81a6,6,0,0,0,8.84,0'
          transform='translate(-247.25 -2279.75)'
        />
        <path
          fill='none'
          stroke={color}
          strokeLinecap='round'
          strokeLinejoin='round'
          strokeWidth='1.5px'
          d='M257,2299.25a9.75,9.75,0,1,0-9.75-9.75A9.75,9.75,0,0,0,257,2299.25Z'
          transform='translate(-247.25 -2279.75)'
        />
      </g>
    </svg>
  )
}

Webcam.defaultProps = {
  color: '#FF7708',
  size: 22
}
