import React from 'react'

export const WorldGlobe = ({ color, size }) => (
  <svg width={size} height={size} viewBox='0 0 17 16'>
    <path
      fill={color}
      d='M14.51,2.343A8.719,8.719,0,0,0,8.5,0,8.719,8.719,0,0,0,2.49,2.343a7.672,7.672,0,0,0,0,11.314A8.719,8.719,0,0,0,8.5,16a8.719,8.719,0,0,0,6.01-2.343,7.672,7.672,0,0,0,0-11.314Zm-9.26-.36q-.165.239-.317.505-.1.18-.2.37-.234-.147-.457-.312A7.275,7.275,0,0,1,5.251,1.984ZM3.271,12.614A6.555,6.555,0,0,1,1.359,8.625H3.634a12.125,12.125,0,0,0,.607,3.33A8.37,8.37,0,0,0,3.271,12.614Zm.363-5.239H1.359A6.555,6.555,0,0,1,3.271,3.386a8.358,8.358,0,0,0,.97.659A12.125,12.125,0,0,0,3.634,7.375Zm.645,6.078q.223-.165.457-.312.1.189.2.37.152.266.317.505A7.284,7.284,0,0,1,4.279,13.453Zm3.557,1.166a3.729,3.729,0,0,1-1.733-1.7q-.1-.178-.2-.368a7.087,7.087,0,0,1,1.928-.458Zm0-3.78a8.478,8.478,0,0,0-2.4.541,11.009,11.009,0,0,1-.477-2.755H7.836Zm0-3.464H4.963A11.007,11.007,0,0,1,5.44,4.62a8.482,8.482,0,0,0,2.4.541Zm0-3.468a7.092,7.092,0,0,1-1.928-.458Q6,3.26,6.1,3.081a3.728,3.728,0,0,1,1.733-1.7Zm5.893-.521a6.555,6.555,0,0,1,1.912,3.989H13.366a12.124,12.124,0,0,0-.607-3.33A8.358,8.358,0,0,0,13.729,3.386Zm-4.565-2a3.728,3.728,0,0,1,1.733,1.7q.1.178.2.368a7.092,7.092,0,0,1-1.928.458Zm0,3.78a8.482,8.482,0,0,0,2.4-.541,11.007,11.007,0,0,1,.477,2.755H9.164ZM10.9,12.919a3.729,3.729,0,0,1-1.733,1.7V12.093a7.087,7.087,0,0,1,1.928.458Q11,12.74,10.9,12.919Zm-1.733-2.08V8.625h2.873a11.009,11.009,0,0,1-.477,2.755A8.478,8.478,0,0,0,9.164,10.839Zm2.585,3.178q.165-.239.317-.505.1-.18.2-.37.234.147.457.312A7.284,7.284,0,0,1,11.749,14.016Zm.515-11.158q-.1-.189-.2-.37-.152-.266-.317-.505a7.275,7.275,0,0,1,.972.563Q12.5,2.712,12.265,2.858Zm1.465,9.756a8.37,8.37,0,0,0-.97-.659,12.124,12.124,0,0,0,.607-3.33h2.275A6.555,6.555,0,0,1,13.729,12.614Z'
      transform='translate(0 0)'
    />
  </svg>
)

WorldGlobe.defaultProps = {
  color: '#FF7708',
  size: 18
}
