import React from 'react'

export const LaptopSmiley = ({ color, size }) => {
  return (
    <svg width={size} height={size} viewBox='0 0 18.985 14.243'>
      <g
        fill='none'
        stroke='#FF7708'
        strokeLinecap='round'
        strokeLinejoin='round'
        strokeWidth='1.2'
      >
        <path
          d='M5.928 6.54a4.4 4.4 0 005.928 0M7.114 2.964V4.15M10.671 2.964V4.15'
          transform='translate(.6 .6)'
        ></path>
        <path
          d='M1.779 7.707V1.186A1.186 1.186 0 012.965 0h11.857a1.186 1.186 0 011.186 1.186v6.521M0 10.078a2.964 2.964 0 002.964 2.964h11.857a2.964 2.964 0 002.964-2.964z'
          transform='translate(.6 .6)'
        ></path>
      </g>
    </svg>
  )
}

LaptopSmiley.defaultProps = {
  color: '#FF7708',
  size: 18
}
